<template>
  <div>
    <b-modal ref="warningDialog" title="Move Selected Studies"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Move Selected Studies" size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      :cancel-disabled="cancelDisabled"
      ok-only ok-title="Cancel" :ok-disabled="cancelDisabled">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">{{ title }}</b-badge>
      <b-alert class="mt-2" variant="warning" show>
        <b-icon icon="exclamation-triangle"/> Moving a study will remove any reports, assignments, and view history stored for it.
      </b-alert>
      <b-form>
        <b-form-group class="mt-2" :label="label">
          <b-form-radio-group v-model="moveGroup" :options="moveGroupOptions">
          </b-form-radio-group>
        </b-form-group>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-progress v-if="cancelDisabled" :max="checkedStudiesForGroup.length" show-value>
              <b-progress-bar variant="danger" :value="studyI-numOK"></b-progress-bar>
              <b-progress-bar variant="success" :value="numOK"></b-progress-bar>
            </b-progress>
          </b-col>
          <b-col cols="6">
            <div class="float-right">
              <b-button @click="handleMove" :disabled="cancelDisabled || (moveGroup=='')" variant="primary">
                <b-spinner v-if="cancelDisabled" small></b-spinner><span v-if="cancelDisabled">&nbsp;Moving Study {{studyI}} of {{checkedStudiesForGroup.length}}...</span>
                <span v-if="!cancelDisabled">Move</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'moveCheckedStudies',
  components: {
  },
  data() {
    return {
      cancelDisabled: false,
      moveGroup: '',
      checkedStudiesForGroup: [],
      groupId: 0,
      groups: [],
      numOK: 0,
      studyI: 0,
      warningMsg: ''
    }
  },
  computed: {
    label() {
      return (this.moveGroupOptions.length == 0) ? "No tenants/groups available for move." : "Move to group/tenant:"
    },
    moveGroupOptions() {
      var moveGroupOpts = []
      for (var i=0; i<this.groups.length; i++) {
        if (i != this.groupId) {
          moveGroupOpts.push({
            text: this.groups[i],
            value: this.groups[i]
          })
        }
      }
      return moveGroupOpts
    },
    tableHeight() {
      return "" + (window.innerHeight - 150) + "px"
    },
    title() {
      if (this.groupId < this.groups.length) {
        const oneOrMore = (this.checkedStudiesForGroup.length == 1) ? "study" : "studies"
        return this.groups[this.groupId]+" ("+this.checkedStudiesForGroup.length+" selected "+oneOrMore+")"
      }
      return "N/A"
    }
  },
  methods: {
    show(groups, groupId=0) {
      if (groupId < groups.length) {
        this.groupId = groupId
        this.groups = groups
        this.moveGroup = ''
        this.numOK = 0
        this.studyI = 0

        const checkedStudies = this.$store.state.checkedStudies
        this.checkedStudiesForGroup = []
        var groupPrefix = this.groups[this.groupId]+'|'
        for (var j=0; j<checkedStudies.length; j++) {
          if (checkedStudies[j].startsWith(groupPrefix)) {
            this.checkedStudiesForGroup.push(checkedStudies[j].replace(groupPrefix, ''))
          }
        }
        if (this.checkedStudiesForGroup.length > 0) {
          this.cancelDisabled = false
          this.$refs['modalRef'].show()
          if (this.moveGroupOptions.length == 1) {
            this.moveGroup = this.moveGroupOptions[0].value
          }
        }
        else {
          this.$log.debug("Skipping group ["+this.groups[this.groupId]+"], no studies selected")
          this.show(this.groups, (this.groupId + 1))
        }
      }
      else if (this.$store.state.checkedStudies.length > 0) {
        this.$store.commit("changeCheckedStudies", [])
        this.warningMsg = "Skipped studies in groups that current user does not have permission to move.";
        this.$refs.warningDialog.show()
      }
    },
    moveOneStudy() {
      this.cancelDisabled = true
      const group = this.groups[this.groupId]
      const studyUid = this.checkedStudiesForGroup[this.studyI]
      const worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
      webServices.updateStudyGroup(group, this.moveGroup, worklistEntry)
      .then(() => {
        this.numOK++
      })
      .catch(err => {
        this.$log.error("Error moving study: "+err.message)
      })
      .finally(() => {
        this.studyI++
        if (this.studyI < this.checkedStudiesForGroup.length) {
          this.moveOneStudy()
        }
        else {
          // Done!
          this.wrapup()
        }
      })
    },
    handleMove() {
      this.studyI = 0
      this.moveOneStudy()
    },
    handleWarningOK() {
      this.warningMsg = ''
    },
    wrapup() {
      if (this.checkedStudiesForGroup.length != this.numOK) {
        const numErrs = this.checkedStudiesForGroup.length - this.numOK
        const oneOrMore = (numErrs == 1) ? "study" : "studies"
        this.warningMsg = "<div>"+numErrs+" "+oneOrMore+" not moved successfully. Contact support to resolve.</div>"
        this.$refs.warningDialog.show()
      }

      this.cancelDisabled = false
      this.checkedStudiesForGroup = []
      this.$refs['modalRef'].hide()

      // Remove handled selections from checked selections.
      //
      var updatedCheckedStudies = []
      const checkedStudies = this.$store.state.checkedStudies
      var groupPrefix = this.groups[this.groupId]+'|'
      for (var j=0; j<checkedStudies.length; j++) {
        if (!checkedStudies[j].startsWith(groupPrefix)) {
          updatedCheckedStudies.push(checkedStudies[j])
        }
      }
      this.$log.debug("checkedStudies n="+checkedStudies.length+" updatedCheckedStudies="+updatedCheckedStudies.length)
      this.$store.commit("changeCheckedStudies", updatedCheckedStudies)

      if (this.numOK > 0) {
        const oneOrMore = (this.numOK == 1) ? "study" : "studies"
        var message = "Moved "+this.numOK+" "+oneOrMore+"."
        this.$bvToast.toast(message, {
          autoHideDelay: 5000,
          solid: true,
          title: 'INSPIRE PACS',
          variant: 'info'
        })
      }
      webServices.readWorklist()

      setTimeout(() => {
        this.show(this.groups, (this.groupId + 1))
      }, 500);
    }
  }
};
</script>
<style scoped>
</style>
import { render, staticRenderFns } from "./WorklistSettings.vue?vue&type=template&id=ec92ed52&scoped=true"
import script from "./WorklistSettings.vue?vue&type=script&lang=js"
export * from "./WorklistSettings.vue?vue&type=script&lang=js"
import style0 from "./WorklistSettings.vue?vue&type=style&index=0&id=ec92ed52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec92ed52",
  null
  
)

export default component.exports
<template>
  <div>
    <b-modal ref="wrapupDialog" title="Import Local Study" size="lg"
      header-bg-variant="primary" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWrapupOK" ok-only>
      <span v-html="wrapupMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Import Local Study" size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow"
      @ok="uploadObjects">
      <template #modal-footer="{ ok, cancel }">
        <b-form class="w-100">
          <b-row>
            <b-col cols="6">
              <div v-if="isTriaging">Inspecting selected files...</div>
              <b-progress v-if="isTriaging || isUploading" :max="objectFiles.length" show-value>
                <b-progress-bar variant="danger" :value="objectFilesI-numOK-numSkipped"></b-progress-bar>
                <b-progress-bar variant="primary" :value="numSkipped"></b-progress-bar>
                <b-progress-bar variant="success" :value="numOK"></b-progress-bar>
              </b-progress>
              <b-progress v-if="isUploading" class="mt-1" :max="objectFileSize" show-progress>
                <b-progress-bar variant="secondary" :value="objectFileUploaded" :label-html="uploadProgress"></b-progress-bar>
              </b-progress>
            </b-col>
            <b-col cols="6">
              <div class="float-right">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button variant="secondary" @click="cancel()" :disabled="isTriaging || isUploading">
                  Cancel
                </b-button>
                <b-button class="ml-2" variant="primary" @click="ok()" :disabled="isTriaging || isUploading || (tenant == null) || (objectFiles.length == 0) || !objectFileSizesOkay">
                  Import
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <b-form>
        <b-row class="mt-2">
          <b-col>
            <b-form-group label="" label-for="dir_mode" label-cols="0">
              <b-form-radio-group id="dir_mode" v-model="selectMode" @change="objectFiles=[]">
                <b-form-radio value="FILE">Select File(s)</b-form-radio>
                <b-form-radio value="DIR">Select Directory</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectMode=='FILE'" class="mt-1">
          <b-col>
            <b-form-group label="" label-for="object_file" label-cols="0">
              <b-form-file
                browse-text="Select File(s)"
                :file-name-formatter="fileNameFormatter"
                class="ml-1"
                v-model="objectFiles"
                id="object_file"
                :disabled="isTriaging || isUploading"
                :state="Boolean(objectFiles.length>0)"
                placeholder="Choose file(s) or drop here..."
                multiple/>
              <b-form-text id="input-live-help">File size limited to {{uploadLimitMb}} MB.&nbsp;
                <b-badge v-if="!objectFileSizesOkay" variant="warning">One or more files exceed this limit.</b-badge>
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectMode=='DIR'" class="mt-1">
          <b-col>
            <b-form-group label="" label-for="object_dir" label-cols="0">
              <b-form-file
                browse-text="Select Directory"
                :file-name-formatter="fileNameFormatter"
                class="ml-1"
                v-model="objectFiles"
                id="object_dir"
                :disabled="isTriaging || isUploading"
                :state="Boolean(objectFiles.length>0)"
                placeholder="Choose folder..."
                no-traverse
                multiple
                directory />
              <b-form-text id="input-live-help">File size limited to {{uploadLimitMb}} MB.&nbsp;
                <b-badge v-if="!objectFileSizesOkay" variant="warning">One or more files exceed this limit.</b-badge>
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="For Tenant:" label-for="tenant">
              <b-form-select v-model="tenant" :options="tenantOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Select study to import:" label-for="study">
              <b-form-select id="study" v-model="studyInfoI" :options="studyOptions" @change="handleStudyChange" :disabled="isTriaging || isUploading || (studyInfo.length == 0)">
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-checkbox id="patient_id_ext" v-model="studyInfoDisplay.patient_id_ext" value="1" unchecked-value="0">
              Include <strong>EX</strong> as MRN prefix
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="acc_num_ext" v-model="studyInfoDisplay.acc_num_ext" value="1" unchecked-value="0">
              Include <strong>EX</strong> as Accession Number prefix
            </b-form-checkbox>
          </b-col>
          <!--
          <b-col>
            <b-form-checkbox id="acc_num_unique" v-model="studyInfoDisplay.acc_num_unique" value="1" unchecked-value="0">
              Ensure Unique Accession Number
            </b-form-checkbox>
          </b-col>
          -->
        </b-row>
      </b-form>
      <div class="box mt-2">
        <b-badge class="w-100" variant="secondary" show>Patient Information</b-badge>
        <b-form>
          <b-row>
            <b-col cols="2">
              <b-form-group label="Prefix:" label-for="patient_name_prefix">
                <b-form-input id="patient_name_prefix" v-model="studyInfoDisplay.patient_name_prefix" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="First Name:" label-for="patient_name_first">
                <b-form-input id="patient_name_first" v-model="studyInfoDisplay.patient_name_first" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Middle Name:" label-for="patient_name_middle">
                <b-form-input id="patient_name_middle" v-model="studyInfoDisplay.patient_name_middle" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Last Name:" label-for="patient_name_last">
                <b-form-input id="patient_name_last" v-model="studyInfoDisplay.patient_name_last" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Suffix:" label-for="patient_name_suffix">
                <b-form-input id="patient_name_suffix" v-model="studyInfoDisplay.patient_name_suffix" placeholder=""/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="MRN (Patient ID):" label-for="patient_id">
                <b-form-input id="patient_id" v-model="studyInfoDisplay.patient_id" placeholder="Enter patient ID" required/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Date of Birth:" label-for="dob">
                <b-form-datepicker id="dob" v-model="studyInfoDisplay.dob_datepicker" @input="handleDobChange"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Sex:" label-for="sex">
                <b-form-radio-group id="sex" v-model="studyInfoDisplay.sex">
                  <b-form-radio value="F">Female</b-form-radio>
                  <b-form-radio value="M">Male</b-form-radio>
                  <b-form-radio value="U">Unknown/Other</b-form-radio>
                  <b-form-radio value="">Not Specified</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Address:" label-for="patient_address">
                <b-form-input id="patient_address" v-model="studyInfoDisplay.patient_address" placeholder="Enter patient address"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Telephone:" label-for="patient_telephone">
                <b-form-input id="patient_telephone" v-model="studyInfoDisplay.patient_telephone" placeholder="Enter patient telephone"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Email:" label-for="patient_email">
                <b-form-input id="patient_email" v-model="studyInfoDisplay.patient_email" type="email" placeholder="Enter patient email address"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="box mt-2">
        <b-badge class="w-100" variant="secondary" show>Study Information</b-badge>
        <b-form>
          <b-row>
            <b-col cols="6">
              Study Date/Time:
              <div class="mt-2">{{studyInfoDisplay.study_date_time}}</div>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Accession Number:" label-for="acc_num">
                <b-form-input id="acc_num" v-model="studyInfoDisplay.acc_num" placeholder="Enter accession number"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Study Description:" label-for="study_desc">
                <b-form-input id="study_desc" v-model="studyInfoDisplay.study_desc" placeholder="Enter study description"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Institution:" label-for="institution">
                <b-form-input id="institution" v-model="studyInfoDisplay.institution" placeholder="Enter institution name"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Institution Address:" label-for="institution_address">
                <b-form-input id="institution_address" v-model="studyInfoDisplay.institution_address" placeholder="Enter institution address"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="box mt-2">
        <b-badge class="w-100" variant="secondary" show>Referring Physician Information</b-badge>
        <b-form>
          <b-row>
            <b-col cols="2">
              <b-form-group label="Prefix:" label-for="physician_name_prefix">
                <b-form-input id="physician_name_prefix" v-model="studyInfoDisplay.physician_name_prefix" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="First Name:" label-for="physician_name_first">
                <b-form-input id="physician_name_first" v-model="studyInfoDisplay.physician_name_first" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Middle Name:" label-for="physician_name_middle">
                <b-form-input id="physician_name_middle" v-model="studyInfoDisplay.physician_name_middle" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Last Name:" label-for="physician_name_last">
                <b-form-input id="physician_name_last" v-model="studyInfoDisplay.physician_name_last" placeholder=""/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Suffix:" label-for="physician_name_suffix">
                <b-form-input id="physician_name_suffix" v-model="studyInfoDisplay.physician_name_suffix" placeholder=""/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dicomParser from 'dicom-parser'
import dicomWeb from '../common/dicomweb'
import permissions from '../common/permissions'
import webServices from '../common/webServices'

const STUDY_INFO_DEFAULT = {
  acc_num: '',
  acc_num_ext: 0,
  acc_num_unique: 0,
  institution: '',
  institution_address: '',
  dob: '',
  dob_datepicker: '',
  patient_age: '',
  patient_address: '',
  patient_email: '',
  patient_id: '',
  patient_id_ext: 0,
  patient_name_last: '',
  patient_name_first: '',
  patient_name_middle: '',
  patient_name_prefix: '',
  patient_name_suffix: '',
  patient_telephone: '',
  physician_name_last: '',
  physician_name_first: '',
  physician_name_middle: '',
  physician_name_prefix: '',
  physician_name_suffix: '',
  sex: '',
  study_date_time: '---',
  study_desc: '',
  study_uid: '',
}

export default {
  name: 'importLocalStudy',
  components: {
  },
  data() {
    return {
      selectMode: 'DIR',
      tenant: null,
      isTriaging: false,
      isUploading: false,
      numOK: 0,
      numSkipped: 0,
      objectDir: null,
      objectFilesI: 0,
      objectFiles: [],
      objectFilesInfo: [],
      objectFileSize: 0,
      objectFileUploaded: 0,
      patient_name_last: '',
      studyInfoDisplay: Object.assign({}, STUDY_INFO_DEFAULT),
      studyInfoI: 0,
      studyInfo: [],
      wrapupMsg: ''
    }
  },
  computed: {
    tenantOptions() {
      var options = []
      try {
        if (this.$store.state.customerId != '') {
          const tenantsForCurrentCustomer = this.$store.state.customers[this.$store.state.customerId].tenants
          const tenantIds = Object.keys(tenantsForCurrentCustomer)
          tenantIds.forEach(tenantId => {
            const can_upload_studies = permissions.hasPermission(tenantsForCurrentCustomer[tenantId].path, permissions.CAN_UPLOAD_STUDY)
            if (can_upload_studies) {
              options.push({
                'value': tenantsForCurrentCustomer[tenantId].path,
                'text': tenantsForCurrentCustomer[tenantId].name
              })
            }
          })
        }
      }
      catch(err) {
        this.$log.error("Error creating options for tenant select: "+err.message)
      }
      if (options.length > 1) {
          options.unshift({'text': 'Select...', 'value': null})
      }
      return options
    },
    studyOptions() {
      var options = []
      for (var i=0; i < this.studyInfo.length; i++) {
        let studyInfo = this.studyInfo[i]
        let patientName = studyInfo.patient_name_last + ', ' + studyInfo.patient_name_first + ' ' + studyInfo.patient_name_middle
        var title = patientName.toUpperCase()
        title += ' (MRN: '+studyInfo.patient_id+') (' + studyInfo.study_date_time + ')'
        options.push({
          value: i,
          text: title,
          disabled: false
        })
      }
      return options
    },
    uploadLimitMb() {
      return this.$store.state.uploadLimitMb;
    },
    uploadProgress(){
      var progress = "100% (waiting for import response)"
      if ( this.objectFileSize > this.objectFileUploaded ) {
        progress = Math.round((this.objectFileUploaded / this.objectFileSize) * 100 ).toString(10)+"%"
      }
      return progress
    },
    objectFileSizesOkay() {
      var okay = true
      for (var i=0; i<this.objectFiles.length; i++) {
        if (this.objectFiles[i].size > this.uploadLimitMb*1024*1024) {
          okay = false
          return
        }
      }
      return okay
    }
  },
  watch: {
    objectFiles(newVal/*, oldVal*/) {
      this.$log.debug('fileList.length='+newVal.length)
      if (newVal.length > 0) {
        this.triageFiles()
      }
    },
  },
  methods: {
    show() {
      this.tenant = (this.tenantOptions.length == 1) ? this.tenantOptions[0].value : null,
      this.numOK = 0
      this.numSkipped = 0
      this.objectFilesI = 0
      this.objectFiles = []
      this.studyInfoI = 0
      this.studyInfo = []
      this.wrapupMsg = ''
      this.isTriaging = false
      this.isUploading = false
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    async triageFiles() {
      this.isTriaging = true
      this.objectFilesI = 0
      this.objectFilesInfo = new Array(this.objectFiles.length)
      this.seriesInfo = []
      this.numOK = 0
      this.numSkipped = 0
      let dicomFound = false
      while(this.objectFilesI  < this.objectFiles.length) {
        let file = this.objectFiles[this.objectFilesI]
        let promise = new Promise((resolve/*, reject*/) => {
          this.$log.debug("START: triage file=["+file.name+"].")
          let _this = this
          file.arrayBuffer()
          .then(result => {
            // Here we have the file data as an ArrayBuffer. dicomParser requires input to be
            // Uint8Array so we create that here.
            //
            var byteArray = new Uint8Array(result);
            
            // Invoke the parseDicom function and get back a DataSet object with the contents
            //
            var dataSet = dicomParser.parseDicom(byteArray);
            if (dataSet.warnings.length > 0) {
              dataSet.warnings.forEach(function(warning) {
                _this.$log.warn(warning);
              });
            }

            var studyInfo = Object.assign({}, STUDY_INFO_DEFAULT)
            studyInfo.acc_num_ext = _this.studyInfoDisplay.acc_num_ext
            studyInfo.acc_num_unique = _this.studyInfoDisplay.acc_num_unique
            studyInfo.patient_id_ext = _this.studyInfoDisplay.patient_id_ext

            var acc_num = dataSet.string("x00080050");
            if (acc_num !== undefined) { 
              studyInfo.acc_num = acc_num;
            }
            var institution = dataSet.string("x00080080");
            if (institution !== undefined) { 
              studyInfo.institution = institution;
            }
            var institution_address = dataSet.string("x00080081");
            if (institution_address !== undefined) { 
              studyInfo.institution_address = institution_address;
            }
            var patient_dob = dataSet.string("x00100030");
            if ((patient_dob !== undefined) && (patient_dob.length == 8)) {
              studyInfo.dob = patient_dob
              studyInfo.dob_datepicker = patient_dob.substr(0, 4)+'-'+patient_dob.substr(4, 2)+'-'+patient_dob.substr(6, 2)
            }
            var patient_address = dataSet.string("x00101040");
            if (patient_address !== undefined) { 
              studyInfo.patient_address = patient_address;
            }
            var patient_email = dataSet.string("x00102155");
            if (patient_email !== undefined) { 
              studyInfo.patient_email = patient_email;
            }
            var patient_id = dataSet.string("x00100020");
            if (patient_id !== undefined) { 
              studyInfo.patient_id = patient_id;
            }
            var patient_name = dataSet.string("x00100010");
            if ((patient_name !== undefined) && (patient_name.length > 0)) {
              let pn_parts = patient_name.split('^');
              if (pn_parts.length > 0) {
                studyInfo.patient_name_last = pn_parts[0]
              }
              if (pn_parts.length > 1) {
                studyInfo.patient_name_first = pn_parts[1]
              }
              if (pn_parts.length > 2) {
                studyInfo.patient_name_middle = pn_parts[2]
              }
              if (pn_parts.length > 3) {
                studyInfo.patient_name_prefix = pn_parts[3]
              }
              if (pn_parts.length > 4) {
                studyInfo.patient_name_suffix = pn_parts[4]
              }
            }
            var patient_telephone = dataSet.string("x00102154");
            if (patient_telephone !== undefined) { 
              studyInfo.patient_telephone = patient_telephone;
            }
            var patient_sex = dataSet.string("x00100040");
            if ((patient_sex !== undefined) && (patient_sex.length > 0) && ((patient_sex == 'M') || (patient_sex == 'F'))) { 
              studyInfo.sex = patient_sex
            }
            var phy_name = dataSet.string("x00080090");
            if ((phy_name !== undefined) && (phy_name.length > 0)) {
              let pn_parts = phy_name.split('^');
              if (pn_parts.length > 0) {
                studyInfo.physician_name_last = pn_parts[0]
              }
              if (pn_parts.length > 1) {
                studyInfo.physician_name_first = pn_parts[1]
              }
              if (pn_parts.length > 2) {
                studyInfo.physician_name_middle = pn_parts[2]
              }
              if (pn_parts.length > 3) {
                studyInfo.physician_name_prefix = pn_parts[3]
              }
              if (pn_parts.length > 4) {
                studyInfo.physician_name_suffix = pn_parts[4]
              }
            }
            var study_date = dataSet.string("x00080020")
            if (study_date === undefined) { 
              study_date = '';
            }
            var study_time = dataSet.string("x00080030")
            if (study_time === undefined) { 
              study_time = '';
            }
            var study_ts = dicomWeb.toTimestamp(study_date, study_time)
            studyInfo.study_date_time = (study_ts==null) ? '---' : new Date(study_ts).toLocaleString(_this.$store.state.locale)
            var study_uid = dataSet.string("x0020000d")
            if (study_uid !== undefined) { 
              studyInfo.study_uid = study_uid;
            }
            var study_desc = dataSet.string("x00081030")
            if (study_desc !== undefined) { 
              studyInfo.study_desc = study_desc;
            }

            if (_this.studyInfo.length == 0) {
                _this.$log.debug("ADD1: study_uid=["+studyInfo.study_uid+"].")
              _this.studyInfo.push(studyInfo)
              _this.studyInfoI = 0
              _this.studyInfoDisplay = studyInfo
            }
            else {
              var found = false
              for (var i=0; i < _this.studyInfo.length; i++) {
                if (studyInfo.study_uid == _this.studyInfo[i].study_uid) {
                  found = true
                  break
                }
              }
              if (!found) {
                _this.$log.debug("ADD2: study_uid=["+studyInfo.study_uid+"].")
                _this.studyInfo.push(studyInfo)
              }
            }
            _this.objectFilesInfo[_this.objectFilesI] = studyInfo.study_uid
            resolve(true)
          })
          .catch(err => {
            this.$log.warn("WARNING: file=["+file.name+"], not able to read/parse as DICOM: " + err)
            _this.objectFilesInfo[_this.objectFilesI] = ''
            resolve(false)
          })
        })
        dicomFound |= await promise
        this.objectFilesI++
        this.numOK++
      }
      if (!dicomFound) {
        this.studyInfoDisplay.study_uid = dicomWeb.generateUid()
      }
      this.isTriaging = false
    },
    fileNameFormatter(files/*, filesTraversed, names*/) {
      var fileNames = "Selected "+files.length+" file(s)"
      if (this.selectMode == 'DIR') {
        const path = files[0].$path
        var lastI = path.lastIndexOf("/")
        if (lastI == -1) { lastI = path.lastIndexOf("\\");  }
        const dirName = path.substring(0,lastI)
        fileNames +=" from "+dirName
      }
      return fileNames
    },
    handleDobChange() {
      const dob = this.studyInfo[this.studyInfoI].dob_datepicker
      this.studyInfo[this.studyInfoI].dob = dob.substring(0,4) + dob.substring(5,7) + dob.substring(8)  
      this.$log.debug(`dob_datepicker=${dob} dob=${this.studyInfo[this.studyInfoI].dob}`)
    },
    handleStudyChange() {
      this.studyInfoDisplay = this.studyInfo[this.studyInfoI]
    },
    uploadOneObject() {
      const objectFile = this.objectFiles[this.objectFilesI]
      var objectFileName = objectFile.name
      this.objectFileSize = objectFile.size
      this.$log.debug("objectFileSize="+this.objectFileSize)
      this.objectFileUploaded = 0
      var reader = new FileReader();
      var _this = this
      reader.onerror = function(/*file*/) {
        _this.$log.error("Error reading file="+objectFileName)
        reader.abort();
        _this.wrapupMsg = "Error reading file="+objectFileName
        _this.$refs.wrapupDialog.show()
        _this.isUploading = false;
      };

      reader.onload = function(/*file*/) {
        _this.$log.debug("Read file="+objectFileName)
        webServices.importStudyObject(_this.tenant, new Blob([reader.result]), objectFileName, _this.studyInfoDisplay, (progressEvent) => {
          _this.objectFileUploaded = Math.min(progressEvent.loaded, _this.objectFileSize)
        })
        .then(() => {
          _this.$log.debug("Uploaded file="+objectFileName)
          if (_this.objectFiles.includes(objectFile)) {
            _this.numOK++
          }
        })
        .catch(err => {
          _this.$log.error("Error importing object: "+err.message)
        })
        .finally(() => {
          if (_this.objectFiles.includes(objectFile)) {
            _this.objectFilesI++
            while ((_this.objectFilesInfo[_this.objectFilesI] != _this.studyInfoDisplay.study_uid) &&
                   (_this.objectFilesI < _this.objectFiles.length)) {
              _this.numSkipped++
              _this.objectFilesI++
            }
            if (_this.objectFilesI < _this.objectFiles.length) {
              _this.uploadOneObject()
            }
            else {
              // Done!
              //
              _this.wrapup()
            }
          }
          else {
            // Response is from a prior upload - ignore
            //
            _this.$log.debug("Ignoring response for previous upload request, object="+objectFileName)
          }
        })
      }
      reader.readAsArrayBuffer(objectFile)
    },
    uploadObjects(event) {
      // Start uploading the objects
      //
      event.preventDefault()
      this.isUploading = true
      this.objectFilesI = 0
      this.numOK = 0
      this.numSkipped = 0
      while ((this.objectFilesInfo[this.objectFilesI] != this.studyInfoDisplay.study_uid) &&
              (this.objectFilesI < this.objectFiles.length)) {
        this.numSkipped++
        this.objectFilesI++
      }
      if (this.objectFilesI < this.objectFiles.length) {
        this.uploadOneObject()
      }
      else {
        this.wrapup()
      }
    },
    handleWrapupOK() {
      this.wrapupMsg = ''
    },
    wrapup() {
      this.isUploading = false
      const numErrs = this.objectFiles.length - this.numOK - this.numSkipped
      this.wrapupMsg = "<div>"+this.numOK
      this.wrapupMsg += (this.numOK == 1) ? " file was " : " files were "
      this.wrapupMsg += "imported successfully.<div>"
      if (this.numSkipped > 0) {
        this.wrapupMsg += "<div>"+this.numSkipped 
        this.wrapupMsg += (this.numSkipped == 1) ? " file was " : " files were "
        this.wrapupMsg += "skipped as not DICOM or not in selected study.</div>"
      }
      if (numErrs > 0) {
        this.wrapupMsg += "<div>"+numErrs
        this.wrapupMsg += (this.numSkipped == 1) ? " file was " : " files were "
        this.wrapupMsg += "not imported due to error(s).</div>"
      }
      this.$refs.wrapupDialog.show()

      webServices.readWorklist()

      this.objectFilesI = 0
      this.objectFiles = []
      this.objectFilesInfo = []
      this.studyInfoI = 0
      this.studyInfo = []
    }
  }
};
</script>
<style scoped>
.box {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}
</style>
<template>
  <div>
    <b-modal ref="modalRef" title="About"
      size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only>
        <b-list-group class="mb-2">
            <b-list-group-item class="bg-dark text-light">
                <span class="font-weight-bold">Version</span>
                <pre class="bg-dark text-light mt-2">{{versionInfo}}</pre>
            </b-list-group-item>
        </b-list-group>
        <div class="float-right">
          <a href="THIRD_PARTY_SOFTWARE.txt" target="_blank">Third-Party Software</a>
        </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'about',
  components: {
  },
  data() {
    return {
        thirdPartyInfo: '',
        versionInfo: '',
    }
  },
  computed: {
  },
  methods: {
    show() {
      this.$refs['modalRef'].show()
      axios.get("VERSION.txt")
      .then(response => {
        this.versionInfo = response.data
      })
      .catch(err => {
        this.$log("Unable to get VERSION.txt: "+err.message)
      })
    }
  }
};
</script>
<style scoped>
a {
  font-size: 0.5rem;
}
a:link {
    color: white;
}
a:visited {
    color: white;
}
a:hover {
    color: white;
}
pre { 
  background-color: gray;
  max-height: 500px;
  overflow-y: auto;
  padding: 2px;
  white-space: pre-wrap;
}
</style>
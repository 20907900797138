<template>
  <div>
    <template v-if="entryToShow.study_uid != ''">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
    </template>
    <b-navbar type="dark" variant="dark">
      <span class="ml-2 font-weight-bold text-light">Order</span>
      <b-navbar-nav class="ml-auto">
        <b-button-group :size="buttonSize">
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close Order Viewer">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <div :style="orderDivStyle">
      <b-alert v-if="fetchingOrder" class="mt-2" variant="info" show>
        <b-spinner class="ml-2" label="Loading..." variant="info"/> Loading order...
      </b-alert>
      <b-alert v-if="orderNotFound" class="m-2" variant="warning" show>Order not found for study.</b-alert>
      <b-alert v-if="!canViewOrder" class="m-2" variant="warning" show>You do not have permission to view orders for {{entryToShow.group}}.</b-alert>
      <b-list-group v-if="(canViewOrder && !orderNotFound && !fetchingOrder)" >
        <b-list-group-item class="bg-dark text-light">
          <span class="ml-2 font-weight-bold">Procedure Information</span>
          <div class="spsTableDiv">
            <b-table responsive="true" outlined small striped head-variant="dark" table-variant="secondary" class="spsTable small"
              :items="procedureItems"
              :fields="procedureFields">
            </b-table>
          </div>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <span class="ml-2 font-weight-bold">Scheduled Procedure Steps</span> <b-badge variant="info" pill>{{this.order.sps.length}}</b-badge>
          <div class="spsTableDiv">
            <b-table responsive="true" outlined small striped head-variant="dark" table-variant="secondary" class="spsTable small"
              :items="spsItems"
              :fields="spsFields">
            </b-table>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import permissions from '../common/permissions'
import webServices from '../common/webServices'

export default {
  name: 'orderViewer',
  components: {
  },
  data() {
    return {
      buttonSize: "sm",
      emptyOrder: {
        sps: []
      },
      entryToShow: webServices.getEmptyWorklistEntry(),
      fetchingOrder: false,
      order: {
        sps: []
      },
      canViewOrder: true,
      orderNotFound: false,
      viewerHeight: '100px'
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    activeStudyUid() {
      return (this.$store.state.activeComponent == 'OrderViewer') ? this.$store.state.activeStudyUid : ''
    },
    entry() {
      var entry = this.$store.getters.worklistEntryForStudy(this.activeStudyUid)
      entry = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
      return entry
    },
    orderDivStyle() {
      return "height: "+this.viewerHeight+"px; overflow: auto;"
    },
    procedureItems() {
      var items = []
      const keys = Object.keys(this.order)
      for(var f=0; f < keys.length; f++) {
        const key = keys[f]
        if (key != 'sps') {
          items.push({ "field": key, "value": this.order[key] })
        }
      }
      return items
    },
    procedureFields() {
      var fieldDefns = []
      fieldDefns.push({ "key": "field", "label": "Field", "sortable": true, "thClass": "bg-secondary text-white position-sticky" })
      fieldDefns.push({ "key": "value", "label": "Value", "sortable": false, "thClass": "bg-secondary text-white position-sticky" })
      return fieldDefns
    },
    spsItems() {
      var items = []
      if (this.order.sps.length > 0) {
        const keys = Object.keys(this.order.sps[0])
        for (var s=0; s < this.order.sps.length; s++) {
          let sps_id = s + 1
          for(var f=0; f < keys.length; f++) {
            const key = keys[f]
            items.push({ "id": sps_id, "field": key, "value": this.order.sps[s][key] })
          }
        }
      }
      return items
    },
    spsFields() {
      var fieldDefns = []
      fieldDefns.push({ "key": "id", "label": "SPS#", "sortable": true, "thClass": "bg-secondary text-white position-sticky" })
      fieldDefns.push({ "key": "field", "label": "Field", "sortable": true, "thClass": "bg-secondary text-white position-sticky" })
      fieldDefns.push({ "key": "value", "label": "Value", "sortable": false, "thClass": "bg-secondary text-white position-sticky" })
      return fieldDefns
    },
    locale() {
      return this.$store.state.locale
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    }
  },
  watch: {
    entry(newVal/*, oldval*/) {
      // Patient/study information may have changed in entry.
      //
      if ((this.$store.state.activeComponent == 'OrderViewer')) {
        if ((newVal != null) && (newVal.study_uid == this.entryToShow.study_uid)) {
          this.entryToShow = newVal
        }
      }
    },
    activeStudyUid(newVal/*, oldVal*/) {
      if ((this.$store.state.activeComponent == 'OrderViewer')) {
        const entry = this.$store.getters.worklistEntryForStudy(newVal)
        this.entryToShow = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
        this.show()
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    }
  },
  methods: {
    show() {
      this.order = this.emptyOrder
      this.canViewOrder = permissions.hasPermission(this.entryToShow.group, permissions.CAN_VIEW_ORDERS)
      this.orderNotFound = false
      if (this.canViewOrder && (this.entryToShow.study_uid != '')) {
        this.fetchingOrder = true
        this.$log.debug(`getting order for study_uid=${this.entryToShow.study_uid}`)
        webServices.readOrder(this.entryToShow)
        .then(orders => {
          this.$log.debug(orders)
          if ((orders != null) && Array.isArray(orders) && (orders.length > 0)) {
            this.order = orders[0]
          }
          else {
            this.orderNotFound = true
          }
        })
        .catch(err => {
          this.$log.error("Unable to get order for study: "+err.message)
          this.orderNotFound = true
        })
        .finally(() => {
          this.fetchingOrder = false
        })
      }
    },
    handleClose() {
      this.$store.commit('changeActiveComponent', '')
    },
    handleResize(/*event*/) {
      this.viewerHeight = window.innerHeight - 175
    }
  }
};
</script>
<style scoped>
pre { 
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.spsTable { 
  text-align: left;
}
</style>
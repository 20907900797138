<template>
  <div>
    <b-modal ref="modalRef" title="Share Study" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" :ok-title="shareStudyLabel" :ok-disabled="disableShareStudy" @ok="shareStudy">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-form class="mt-2 p-2 border rounded">
        <span class="mt-1 font-weight-bold">
          Recipient Details
          <b-badge class="ml-1" v-if="userNameUndefined" variant="warning">Update User→Profile with your first and last name to add recipients.</b-badge>
        </span>
        <b-row class="mt-1">
          <b-col cols="9">
            <b-form-group label="Select Recipient:" label-for="shareAddressId">
              <b-form-select v-model="shareAddressId" :options="shareAddressOptions" @change="shareAddressChange">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="text-right text-center">
            <b-button @click="handleShareAddress" variant="primary" :disabled="addOrUpdateDisabled">
              {{ addOrUpdateLabel }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="2">
            <b-form-group label="Prefix:" label-for="patient_name_prefix">
              <b-form-input id="patient_name_prefix" v-model="shareData.name_prefix" placeholder="" :disabled="userNameUndefined"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="*First Name:" label-for="patient_name_first">
              <b-form-input id="patient_name_first" v-model="shareData.name_first" placeholder="" :disabled="userNameUndefined"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="*Last Name:" label-for="patient_name_last">
              <b-form-input id="patient_name_last" v-model="shareData.name_last" placeholder="" :disabled="userNameUndefined"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Suffix:" label-for="patient_name_suffix">
              <b-form-input id="patient_name_suffix" v-model="shareData.name_suffix" placeholder="" :disabled="userNameUndefined"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Email:" label-for="email">
              <b-form-input id="email" v-model="shareData.email" type="email" placeholder="Enter email address" :disabled="userNameUndefined"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Mobile Number (SMS):" label-for="mobile">
              <b-form-input id="mobile" v-model="shareData.mobile" type="tel" placeholder="Enter mobile telephone number" :disabled="userNameUndefined"/>
              <b-form-text id="mHelp">Include country code.</b-form-text>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="WhatsApp Number:" label-for="whatsapp">
              <b-form-input id="whatsapp" v-model="shareData.whatsapp" type="tel" placeholder="Enter WhatsApp number" :disabled="userNameUndefined"/>
              <b-form-text id="waHelp">Include country code.</b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="text-right">
        &nbsp;<b-badge v-if="shareAddressDirty" variant="warning">Unsaved changes for Recipient</b-badge>
      </div>
      <div class="text-right">
        <b-badge class="ml-2" v-if="messageSupport.email" variant="success">Email Available</b-badge>
        <b-badge class="ml-2" v-if="!messageSupport.email" variant="warning">Email Unavailable</b-badge>
        <b-badge class="ml-2" v-if="messageSupport.sms" variant="success">SMS Available</b-badge>
        <b-badge class="ml-2" v-if="!messageSupport.sms" variant="warning">SMS Unavailable</b-badge>
        <b-badge class="ml-2" v-if="messageSupport.whatsapp" variant="success">WhatsApp Available</b-badge>
        <b-badge class="ml-2" v-if="!messageSupport.whatsapp" variant="warning">WhatsApp Unavailable</b-badge>
      </div>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

const emptyShareData = {
  share_address_id: null,
  name_prefix: '',
  name_first: '',
  name_last: '',
  name_suffix: '',
  email: '',
  mobile: '',
  whatsapp: ''
}

export default {
  name: 'shareStudy',
  components: {
  },
  data() {
    return {
      messageSupport: {
        'email': false,
        'sms': false,
        'whatsapp': false
      },
      shareAddresses: [],
      shareAddressId: null,
      shareData: { ...emptyShareData },
      savedShareData: { ...emptyShareData },
      studyUid: '',
      sharing: false
    }
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    addOrUpdateDisabled() {
      return this.userNameUndefined || !this.shareAddressDirty || (this.shareData.name_first.length == 0) || (this.shareData.name_last.length == 0) ||
        ((this.shareData.email.length == 0) && (this.shareData.mobile.length == 0) && (this.shareData.whatsapp.length == 0))
    },
    addOrUpdateLabel() {
      return (this.shareAddressId == null) ? "Add" : "Update"
    },
    userNameUndefined() {
      return (this.$store.getters.user == '')
    },
    shareAddressDirty() {
      const keys = Object.keys(this.shareData)
      for (var i = 0; i < keys.length; i++) {
        if (this.shareData[keys[i]] != this.savedShareData[keys[i]]) {
          return true
        }
      }
      return false
    },
    shareAddressOptions() {
      let options = [
        { value: null, text: 'Add new...' }
      ]
      for (var i=0; i < this.shareAddresses.length; i++) {
        let sa = this.shareAddresses[i]
        options.push( {
          value: sa.id,
          text: `${sa.name_prefix} ${sa.name_first} ${sa.name_last} ${sa.name_suffix}`
        })
      }
      return options
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    shareStudyLabel() {
      return (this.sharing) ? "Sharing..." : "Share Study"
    },
    disableShareStudy() {
      var availMessageType = (this.messageSupport.email && (this.shareData.email != '')) ||
            (this.messageSupport.sms && (this.shareData.mobile != '')) ||
            (this.messageSupport.whatsapp && (this.shareData.whatsapp != ''))
      return this.shareAddressDirty || (this.shareAddressId == null) || !availMessageType || this.sharing
    }
  },
  methods: {
    show(studyUid) {
      this.shareAddressId = null
      this.shareData = { ...emptyShareData }
      this.savedShareData = { ...emptyShareData }
      this.studyUid = studyUid
      this.sharing = false
      webServices.readSystemSetting('messageSupport')
      .then(response => {
        this.messageSupport = response
      })
      .catch(err => {
        this.$log.warn(`Unable to get message support settings: ${err.message}`)
      })
      webServices.readShareAddresses()
      .then(response => {
        this.shareAddresses = response.addresses
      })
      .catch(err => {
        this.$log.warn(`Unable to get user's address book: ${err.message}`)
      })
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    handleShareAddress() {
      if (this.shareAddressId == null) {
        // Add new...
        //
        webServices.createShareAddress(this.shareData)
        .then(response => {
          this.$log.debug(`Added entry id=${response}`)
          let newShareAddress = { ...this.shareData }
          newShareAddress.id = response.share_address_id
          this.shareAddresses.push(newShareAddress)
          this.shareAddressId = newShareAddress.id
          this.savedShareData = { ...this.shareData }
        })
        .catch(err => {
          this.$log.warn(`Unable to add entry to address book: ${err.message}`)
          let msg =  "Unable to add entry to address book."
          this.$bvToast.toast(msg, {
              autoHideDelay: 5000,
              solid: true,
              title: 'INSPIRE PACS',
              variant: 'danger',
          })
        })
      }
      else {
        // Update existing...
        //
        webServices.updateShareAddress(this.shareData)
        .then(response => {
          this.$log.debug(`Updated entry id=${response}`)
          this.savedShareData = { ...this.shareData }
          let tmpShareAddresses = JSON.parse(JSON.stringify(this.shareAddresses))
          let updatedShareAddress = { ...this.shareData }
          for (var i=0; i < tmpShareAddresses.length; i++) {
            if (tmpShareAddresses[i].id == updatedShareAddress.id) {
              this.$log.debug(`Replacing in list, i=${i}`)
              tmpShareAddresses[i] = updatedShareAddress
              break;
            }
          }
          this.shareAddresses = tmpShareAddresses
        })
        .catch(err => {
          this.$log.warn(`Unable to add entry to address book: ${err.message}`)
          let msg =  "Unable to add entry to address book."
          this.$bvToast.toast(msg, {
              autoHideDelay: 5000,
              solid: true,
              title: 'INSPIRE PACS',
              variant: 'danger',
          })
        })
      }
    },
    shareAddressChange() {
      let selected = this.shareAddresses.filter(obj => {
        return obj.id === this.shareAddressId
      })
      if (selected.length == 1) {
        this.$log.debug(`Found entry for ${this.shareAddressId}`)
        this.shareData = { ...selected[0] }
        this.savedShareData = { ...selected[0] }
      }
      else {
        this.$log.debug(`Did not find entry for ${this.shareAddressId}`)
        this.shareData = { ...emptyShareData }
        this.savedShareData = { ...emptyShareData }
      }
    },
    shareStudy(bvModalEvent) {
      this.sharing = true
      bvModalEvent.preventDefault()
      webServices.shareStudy(this.entry, this.shareAddressId)
      .then(() => {
        let msg =  "[" + webServices.getTitleForEntry(this.entry) + "] Study link sent."
        this.$bvToast.toast(msg, {
            autoHideDelay: 5000,
            solid: true,
            title: 'INSPIRE PACS',
            variant: 'info',
        })
        this.hide()
      })
      .catch(err => {
        this.$log.error("Error routing study: "+err.message)
        let msg =  "[" + webServices.getTitleForEntry(this.entry) + "] Error sharing study."
        this.$bvToast.toast(msg, {
            autoHideDelay: 5000,
            solid: true,
            title: 'INSPIRE PACS',
            variant: 'danger',
        })
        this.sharing = false
      })
    }
  }
};
</script>
<style scoped>
</style>
<template>
  <div>
    <b-modal ref="warningDialog" title="Assign Selected Studies"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" title="Assign Selected Studies" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      :cancel-disabled="cancelDisabled"
      ok-title="Save" @ok="handleSave()" :ok-disabled="checkedUsers.length==0">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">{{ title }}</b-badge>
      <b-badge class="d-none d-sm-block overflow-hidden" variant="secondary" show size="sm">{{ title2 }}</b-badge>
      <b-table outlined
          responsive="true"
          :sticky-header="tableHeight"
          :items="filteredUserOptions"
          :fields="fields"
          primary-key="text">
        <template slot="top-row">
          <td colspan="2">
            <b-form-input ref="userFilterInput" v-model="userFilter" placeholder="Filter for user..." autofocus @update="templateId = ''"></b-form-input>
          </td>
        </template>
        <template #cell(actions)="data">
          <div class="float-right"><b-checkbox v-model="checkedUsers" :value="data.item.value" :disabled="data.item.value==''"/></div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'assignCheckedStudies',
  components: {
  },
  data() {
    return {
      cancelDisabled: false,
      checkedUsers: [],
      checkedStudiesForGroup: [],
      fields: [
        { "key": "actions", "label": "", "sortable": false, "thClass": "bg-secondary text-white" },
        { "key": "text", "label": "User(s)", "sortable": true, "thClass": "bg-secondary text-white" },
      ],
      groupId: 0,
      groups: [],
      userFilter: '',
      userOptions: [],
      warningMsg: ''
    }
  },
  computed: {
    filteredUserOptions() {
      var filteredUserOpts = this.userOptions.filter(item => {
        return item['text'].match(new RegExp(this.userFilter, "i"))
      })
      if (filteredUserOpts.length == 0) {
        filteredUserOpts.push({ value: '', text: 'No users match', username: '', _rowVariant: 'warning' })
      }
      return filteredUserOpts
    },
    tableHeight() {
      return "" + (window.innerHeight - 150) + "px"
    },
    title() {
      if (this.groupId < this.groups.length) {
        const oneOrMore = (this.checkedStudiesForGroup.length == 1) ? "study" : "studies"
        return this.groups[this.groupId]+" ("+this.checkedStudiesForGroup.length+" selected "+oneOrMore+")"
      }
      return "No group selected"
    },
    title2() {
      return ""+this.checkedUsers.length+" selected users"
    }
  },
  methods: {
    show(groups, groupId=0) {
      // Some convoluted logic to handle studies selected across tenants with different users.
      //
      if (groupId < groups.length) {
        this.checkedUsers = []
        this.userFilter = ''
        this.groupId = groupId
        this.groups = groups

        const checkedStudies = this.$store.state.checkedStudies
        this.checkedStudiesForGroup = []
        var groupPrefix = this.groups[this.groupId]+'|'
        for (var j=0; j<checkedStudies.length; j++) {
          if (checkedStudies[j].startsWith(groupPrefix)) {
            this.checkedStudiesForGroup.push(checkedStudies[j].replace(groupPrefix, ''))
          }
        }
        if (this.checkedStudiesForGroup.length > 0) {
          this.cancelDisabled = false
          this.userOptions = []
          this.$refs['modalRef'].show()
          webServices.readGroupUsers('/'+this.groups[this.groupId])
          .then(response => {
            const responseUserIds = Object.keys(response)
            if (responseUserIds.length > 0) {
              this.userOptions = []
              for (var i=0; i < responseUserIds.length; i++) {
                const userId = responseUserIds[i]
                this.userOptions.push({
                  value: userId,
                  text: response[userId].full_name,
                  username: response[userId].username,
                  _rowVariant: 'primary'
                })
              }
            }
          })
          .catch(err => {
            this.$log.error("Error getting group users: "+err.message)
          })
        }
        else {
          this.$log.debug("Skipping group ["+this.groups[this.groupId]+"], no studies selected")
          this.show(this.groups, (this.groupId + 1))
        }
      }
      else if (this.$store.state.checkedStudies.length > 0) {
        this.$store.commit("changeCheckedStudies", [])
        this.warningMsg = "Skipped studies in groups that current user does not have permission to assign.";
        this.$refs.warningDialog.show()
      }
    },
    handleSave() {
      this.cancelDisabled = true
      var userEntries = []
      for(var i=0; i < this.userOptions.length; i++) {
        const userOption = this.userOptions[i]
        for (var j=0; j < this.checkedUsers.length; j++) {
          if (userOption.value == this.checkedUsers[j]) {
            userEntries.push({
              userId: userOption.value,
              userFullName: userOption.text,
              username: userOption.username
            })
          }
        }
      }
      var allUpdatePromises = []
      const group = this.groups[this.groupId]
      for (var s=0; s < this.checkedStudiesForGroup.length; s++) {
        let updatePromise = webServices.updateStudyAssignment(group, this.checkedStudiesForGroup[s], userEntries)
        allUpdatePromises.push(updatePromise)
      }
      Promise.allSettled(allUpdatePromises)
      .then(() => {
        webServices.readWorklist()
      })
      .catch(err => {
        this.$log.error("Error assigning study: "+err)
        this.warningMsg = 'Error assigning study, changes not saved.'
        this.$refs.warningDialog.show()
      })
      .finally(() => {
        this.cancelDisabled = false
        this.checkedStudiesForGroup = []
        this.$refs['modalRef'].hide()

        // Remove handled selections from checked selections.
        //
        var updatedCheckedStudies = []
        const checkedStudies = this.$store.state.checkedStudies
        var groupPrefix = this.groups[this.groupId]+'|'
        for (var j=0; j<checkedStudies.length; j++) {
          if (!checkedStudies[j].startsWith(groupPrefix)) {
            updatedCheckedStudies.push(checkedStudies[j])
          }
        }
        this.$log.debug("checkedStudies n="+checkedStudies.length+" updatedCheckedStudies="+updatedCheckedStudies.length)
        this.$store.commit("changeCheckedStudies", updatedCheckedStudies)

        this.show(this.groups, (this.groupId + 1))
      })
    },
    handleWarningOK() {
      this.warningMsg = ''
    }
  }
};
</script>
<style scoped>
</style>
<template>
  <div>
    <b-modal ref="wDialogRef" title="Worklist Changes Not Saved"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span>{{wDialogMsg}}</span>
    </b-modal>
    <b-modal ref="modalRef" title="Worklist Settings"
      size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleOK"
      no-close-on-esc no-close-on-backdrop hide-header-close>
      <b-list-group class="mt-2">
        <b-list-group-item class="bg-dark text-light">
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group label="Worklist Name" label-for="worklistName"> 
                  <b-form-input id="worklistName" v-model="worklistFilter.worklistName" placeholder="Enter name for worklist" required>{{worklistFilter.worklistName}}</b-form-input>
                  <b-form-checkbox id="default" v-model="worklistFilter.default" :disabled="defaultDisabled">Use as default</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Institution Name" label-form="institution" description="Use * for wildcard matches">
                  <b-form-input id="institution" v-model="worklistFilter.institution" placeholder="Leave empty for any" required>{{worklistFilter.institution}}</b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group label="Patient Name" label-form="patientName" description="Use * for wildcard matches">
                  <b-form-input id="patientName" v-model="worklistFilter.patientName" placeholder="Leave empty for any" required>{{worklistFilter.patientName}}</b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="MRN/Patient ID" label-form="patientId" description="Use * for wildcard matches">
                  <b-form-input id="patientId" v-model="worklistFilter.patientId" placeholder="Leave empty for any" required>{{worklistFilter.patientId}}</b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group label="Accession Number" label-form="accessionNumber" description="Use * for wildcard matches">
                  <b-form-input id="accessionNumber" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" required>{{worklistFilter.patiaccessionNumberentName}}</b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Study ID" label-form="studyId" description="Use * for wildcard matches">
                  <b-form-input id="studyId" v-model="worklistFilter.studyId" placeholder="Leave empty for any" required>{{worklistFilter.studyId}}</b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group label="Study Dates">
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="">Any</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="today">Today</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="yesterday">Yesterday</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="threeDays">Last 3 days</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="week">Last week</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="month">Last month</b-form-radio>
                  <b-form-radio v-model="worklistFilter.studyDateFilter" name="studyDateFilter" value="custom">Custom (enter from and to dates)</b-form-radio>
                </b-form-group>
              </b-col>
              <b-col>
                <label>Custom</label><br/>
                <b-form-group label="(from)" label-for="studyDateFrom" description="Leave empty for January 1, 1900" >
                  <b-form-datepicker id="studyDateFrom" v-model="worklistFilter.studyDateFrom" :disabled="worklistFilter.studyDateFilter!='custom'"/>
                </b-form-group>
                <b-form-group label="(to)" label-for="studyDateTo" description="Leave empty for today" >
                  <b-form-datepicker id="studyDateTo" v-model="worklistFilter.studyDateTo" :disabled="worklistFilter.studyDateFilter!='custom'"/>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <b-form-group label="Modalities" label-for="modalities" description="Unselect all for any modality">
            <b-form-checkbox-group id="modalities" v-model="worklistFilter.modalities">
              <b-form-checkbox class="cbM" v-for="m in modalities" :key="m" :value="m">{{m}}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-list-group-item>
        <b-list-group-item class="bg-dark text-light">
          <b-form-group label="Tenant" label-for="aeTitles" description="Unselect all for any">
            <template v-if="groups.length > 0">
              <b-form-checkbox-group id="groups" v-model="worklistFilter.groups">
                <b-form-checkbox v-for="group in groups" :key="group.value" :value="group.value">{{group.display}}</b-form-checkbox>
              </b-form-checkbox-group>
            </template>
            <template v-else>
              No locations assigned to current user.
            </template>
          </b-form-group>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import WorklistFilter from '../common/worklistFilter'
import Vue from "vue"

export default {
  name: 'worklistSettings',
  components: {
  },
  data() {
    return {
      defaultDisabled: true,
      modalities: ['CR', 'CT', 'DR', 'DX', 'ES', 'MG', 'MR', 'NM', 'OT', 'PT', 'RF', 'RG', 'SC', 'SR', 'US', 'VL', 'XA', 'XC'],
      origWorklistName: '',
      worklistFilter: new WorklistFilter(''),
      wDialogMsg: 'Worklist name must be specified.'
    };
  },
  mounted() {
  },
  computed: {
    groups() {
      var groupsForUser = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          var can_view_studies = permissions.hasPermission(groupsFromToken[i], permissions.CAN_VIEW_STUDIES)
          can_view_studies |= permissions.hasPermission(groupsFromToken[i], permissions.CAN_VIEW_STUDIES_ALL)
          if (can_view_studies) {
            groupsForUser.push({
              'display': groupsFromToken[i].substring(1),
              'value': groupsFromToken[i]
            })
          }
        }
        this.$log.debug(groupsForUser)
      }
      catch {
        groupsForUser = []
      }
      return groupsForUser
    }
  },
  methods: {
    show(worklistName, reload) {
      this.origWorklistName = worklistName
      if (reload) {
        this.worklistFilter = new WorklistFilter('')
        if (worklistName != '') {
          const storedWorklistFilter = this.$store.getters.worklistFilter(worklistName);
          if (storedWorklistFilter != null) {
            this.worklistFilter.copy(storedWorklistFilter)
          }
          else {
            Vue.$log.debug("worklistName=["+worklistName+"] not found in store")
          }
          this.worklistFilter.worklistName = worklistName

          // Remove groups that were checked but no longer accessible.
          //
          var groups = []
          for (var i=0; i<this.worklistFilter.groups.length; i++) {
            var can_view_studies = permissions.hasPermission(this.worklistFilter.groups[i], permissions.CAN_VIEW_STUDIES)
            can_view_studies |= permissions.hasPermission(this.worklistFilter.groups[i], permissions.CAN_VIEW_STUDIES_ALL)
            if (can_view_studies) {
              groups.push(this.worklistFilter.groups[i])
            }
          }
          this.worklistFilter.groups = groups
        }
      }
      this.defaultDisabled = this.worklistFilter.default;
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    handleOK() {
      if (this.worklistFilter.worklistName == '') {
        this.wDialogMsg = 'Worklist name must be specified.'
        this.$refs['wDialogRef'].show()
      }
      else if ((this.worklistFilter.worklistName != this.origWorklistName) &&
               (this.$store.state.worklistNames.indexOf(this.worklistFilter.worklistName) > -1)) {
        this.worklistFilter.worklistName = this.origWorklistName
        this.wDialogMsg = 'Worklist name has already been used.'
        this.$refs['wDialogRef'].show()
      }
      else {
        this.$log.debug("Updating cache for worklistSettings")
        const worklistSettingsBackup = JSON.stringify(this.$store.state.worklistSettings)
        this.$store.commit('changeWorklistFilter', this.worklistFilter)
        if ((this.origWorklistName != '') && (this.worklistFilter.worklistName != this.origWorklistName)) {
          this.$store.commit('removeWorklistFilter', this.origWorklistName)
        }
        webServices.updateUserSetting("worklistSettings", this.$store.state.worklistSettings)
        .then(() => {
          this.$log.debug('Refeshing worklist')
          this.$store.commit('changeWorklistName', this.worklistFilter.worklistName)
          this.$store.commit("changeSelectedStudyUids", "")
          webServices.readWorklist()
        })
        .catch(err => {
          this.$log.error("Error updating cached settings: "+err)
          webServices.updateUserSetting("worklistSettings", JSON.parse(worklistSettingsBackup))
          this.wDialogMsg = 'Unable to save new settings.'
          this.$refs['wDialogRef'].show()
        })
      }
    },
    handleWarningOK() {
      this.$refs['modalRef'].show(this.origWorklistName, false);
    }
  }
};
</script>
<style scoped>
.cbM {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
</style>